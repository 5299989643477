<template>
	<div
		:class="{ 'ranking-item--highlited': itsMe, 'skeleton-play': skeleton }"
		class="ranking-item d-flex justify-content-between"
	>
		<div class="ranking-item__identification d-flex align-items-center col-6 p-0">
			<TextPlay
				class="ranking-item__identification__position mr-3"
				color="var(--fontcolor)"
				:text="position"
				size="sm"
				weight="semibold"
			/>
			<AvatarPlay
				class="ranking-item__identification__avatar"
				customSize="32px"
				:src="avatar"
				:name="name"
			/>
			<TextPlay
				class="ranking-item__identification__name ml-2"
				color="var(--fontcolor)"
				:text="name"
				size="sm"
				weight="semibold"
			/>
		</div>

		<div class="ranking-item__info d-flex col-6 p-0">
			<div class="ranking-item__info__medals-container d-flex">
				<TextPlay
					v-if="medalList.length > 4"
					class="see-all"
					size="xs"
					color="var(--text-color)"
					:text="$t('gamification.see_all')"
					v-b-modal="id"
				/>
				<Emblem
					v-for="(medal, index) in medalListSliced"
					:id="medal.id"
					:key="index"
					size="xs"
					class="medals mr-1"
					:src="medal.emblem.picture"
					:tooltip="medal.name"
				/>
			</div>
			<TextPlay
				class="ranking-item__info__points ml-4"
				weight="semibold"
				size="sm"
				color="var(--fontcolor)"
				:text="$t('gamification.points', { points })"
			/>
			<StudentGamificationInfoModal
				v-if="medalList.length > 4"
				:memberId="memberId"
				:id="id"
				:name="name"
				:position="position"
				:points="points"
				:medalList="medalList"
			/>
		</div>
	</div>
</template>

<script>
import Emblem from "@/components/Emblem.vue";
import StudentGamificationInfoModal from "@/components/StudentGamificationInfoModal.vue";

export default {
	components: {
		Emblem,
		StudentGamificationInfoModal,
	},
	data() {
		return {
			id: `student-gamification-info-${this._uid}`,
			medalName: undefined,
		};
	},
	props: {
		memberId: {
			type: [Number, String],
		},
		position: {
			type: [Number, String],
			default: 0,
		},
		avatar: {
			type: String,
		},
		name: {
			type: String,
		},
		points: {
			type: [Number, String],
			default: 0,
		},
		itsMe: {
			type: Boolean,
			default: false,
		},
		medalList: {
			type: Array,
			default: () => [],
		},
		skeleton: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		medalListSliced() {
			return this.medalList.slice(0, 5) || [];
		},
	},
};
</script>

<style lang="scss" scoped>
.ranking-item {
	background-color: var(--box-background-color);
	border-radius: 4px;
	padding: 10px 16px;

	&--highlited {
		border: 1px solid var(--maincolor);
	}

	&__identification {
		&__position {
			width: 5ch;
		}

		&__name {
			width: 300px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	&__info {
		align-items: center;
		justify-content: flex-end;

		&__medals-container {
			display: flex;
			align-items: center;

			.see-all {
				margin-right: 4px;
			}
		}

		&__points {
			width: 12ch;
		}
	}
}

@media (max-width: 768px) {
	.ranking-item {
		width: 100.4vw;
		transform: translateX(-24px);
		border-radius: 0;
		align-items: start;
		min-height: 88px;

		&__identification {
			&__position {
				width: 20px;
			}
			&__name {
				width: 70px;
			}
		}

		&__info {
			flex-direction: column-reverse;
			align-items: end;
			padding: 0.4rem 0 !important;

			&__medals-container {
				justify-content: end;

				.see-all {
					order: 2;
					text-wrap: nowrap;
					margin: 0 0 0 4px;
				}

				.medals {
					order: 1;
				}
			}

			&__points {
				text-align: end;
				margin-bottom: 1rem;
			}
		}
	}
}
</style>
